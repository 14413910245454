.title.s {
    font-size: 16px;
}

.title.m {
    font-size: 18px;
}

.title.l {
    font-size: 20px;
}

.title.xl {
    font-size: 30px;
}

.title.xxl {
    font-size: 44px;
}

.title.bold {
    font-weight: 600;
}

.title.regular {
    font-weight: 400;
}

@media only screen and (max-width: 800px) {
    .title.xxl {
        font-size: 30px;
    }

    .profile-img.xl {
        width: 80px;
        height: 80px;
    }
}
