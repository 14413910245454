.text-field {
    line-height: 1.5;
    word-break: break-word;
}

.text-field.xs {
    font-size: 12px;
}

.text-field.s {
    font-size: 14px;
}

.text-field.m {
    font-size: 16px;
}

.text-field.l {
    font-size: 18px;
}

.text-field.xl {
    font-size: 22px;
}

.text-field.xxl {
    font-size: 44px;
}

.text-field.bold {
    font-weight: 600;
}

.text-field.regular {
    font-weight: 400;
}

@media only screen and (max-width: 800px) {
    .text-field.xs {
        font-size: 10px;
    }

    .text-field.s {
        font-size: 12px;
    }

    .text-field.m {
        font-size: 14px;
    }

    .text-field.l {
        font-size: 16px;
    }

    .text-field.xl {
        font-size: 20px;
    }

    .text-field.xxl {
        font-size: 40px;
    }
}
