.homepage {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.homepage-hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url('../../images/homepage-image-4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 600px;
    position: relative;
}

.homepage-hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    width: 100%;

    & > h3 {
        margin-bottom: 30px;
    }

    & > button {
        margin-top: 30px;
    }
}

.homepage-main {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    height: 320px;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 35px;
        padding-right: 20px;
    }
}

.homepage-main-inverted {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    & > div {
        display: flex;
        flex-direction: column;
        gap: 35px;
        padding-left: 40px;
        flex: 1;
    }
}

.homepage-image {
    height: auto;
    width: 470px;
    flex: 1;
}

.homepage-text > h3 {
    margin-bottom: 40px;
}

.homepage-text > p {
    margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
    .homepage {
        gap: 0px;
    }

    .homepage-image {
        text-align: center;
        height: 30vh;
        width: auto;
    }

    .homepage-main,
    .homepage-main-inverted {
        flex-direction: column;
        gap: 35px;

        & > div {
            padding: 0;
            text-align: center;
        }

        & > div > button {
            margin: 0 auto;
        }
    }
}
