.find-event-inputs {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.event-list {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.event-list-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .find-events {
        padding: 10px 10px;
    }

    .find-event-inputs {
        gap: 5px;
    }
}
