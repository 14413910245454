input {
    font-size: 14px;
    padding: 20px 15px;
    border-radius: 15px;
    height: 48px;
    border: 2px solid #dbdde0;
    width: 100%;
}

input:focus,
textarea:focus {
    border: 2px solid #5b956d;
}

input.full-width {
    width: auto;
}

.input-container {
    display: flex;
    flex-direction: column;
}

.input-wrapper {
    position: relative;
}

label {
    display: inline-block;
    margin-bottom: 10px;
    min-height: 18px;
    text-align: left;
    font-size: 14px;
}

textarea {
    font-size: 14px;
    resize: none;
    border: 1px solid #d1d5da;
    border-radius: 15px;
    padding: 10px 15px;
    width: 100%;
    height: 100px;
    line-height: 1.4;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

textarea.big {
    height: 300px;
}

input.error {
    border: 1px solid red;
}

.error-message {
    padding: 3px 0;
    color: red;
    font-size: 14px;
}

.input-container > i:first-child {
    height: 18px;
    position: absolute;
    top: 15px;
    left: 15px;
}

.input-container > i:last-child {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 20px;
}

.extra-padding {
    padding: 5px 40px;
}

.double-input-wrapper {
    display: flex;
}

.double-input-wrapper > div {
    flex: 1;
}

.double-input-wrapper > div:first-child {
    margin-right: 20px;
}

@media only screen and (max-width: 800px) {
    .double-input-wrapper > div:first-child {
        max-width: 50%;
        margin-right: 10px;
    }

    textarea {
        font-size: 14px;
        resize: none;
        height: 100px;
        border: 1px solid #d1d5da;
        border-radius: 5px;
        padding: 7px;
        width: 100%;
        height: 50px;
        line-height: 1.4;
    }
}
