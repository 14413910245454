.autocomplete-container {
    display: inline-block;
    position: relative;
    width: 300px;
}

.autocomplete-container.full-width {
    width: 100%;
}

.autocomplete-menu {
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;
    position: absolute;
    top: 52px;
    background: white;
    overflow: auto;
    max-height: 300px;
    width: 100%;
}

.autocomplete-menu-option {
    padding: 7px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.autocomplete-menu-option:hover {
    background-color: #d1d5da;
}

.autocomplete-icon {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #d8dee2;
    height: 34px;
    border-radius: 0 5px 5px 0;
    padding: 9px 10px;
    cursor: pointer;
}

.search-icon {
    height: 18px;
    position: absolute;
    top: 8px;
    left: 10px;
}

.autocomplete-container > i {
    position: absolute;
    right: 8px;
    height: 18px;
    top: 4px;
    background-color: #f1f1f1;
    border-radius: 55px;
    color: #3f3f3f;
    cursor: pointer;
}
