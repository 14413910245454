.radio-button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.radio-button {
    display: flex;
    justify-content: space-between;
    border: 1px solid #dedede;
    padding: 10px;
    border-radius: 10px;
    flex: 1;
}

.radio-button.checked {
    border: 1px solid #329f5b;
    background-color: #2dc5841c;
}

.radio-button > div {
    display: flex;
    flex-direction: column;
}

.radio-button-options {
    display: flex;
    gap: 10px;
}

input[type='radio'] {
    width: 30px;
    height: 20px;
    accent-color: #329f5b;
    cursor: pointer;
}
