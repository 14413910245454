.bx-squash:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    background: url('../../../images/squash.png') no-repeat 0 0;
    background-size: 100%;
}

.bx-volleyball:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    background: url('../../../images/volleyball.png') no-repeat 0 0;
    background-size: 100%;
}

.bx-tennis:before {
    display: inline-block;
    width: 25px;
    height: 25px;
    content: '';
    background: url('../../../images/tennis.png') no-repeat 0 0;
    background-size: 100%;
}

.bx-football:before {
    display: inline-block;
    width: 22px;
    height: 22px;
    content: '' !important;
    background: url('../../../images/football.png') no-repeat 0 0;
    background-size: 100%;
}

.bx-basketball:before {
    display: inline-block;
    width: 22px;
    height: 22px;
    content: '' !important;
    background: url('../../../images/basketball.png') no-repeat 0 0;
    background-size: 100%;
}
