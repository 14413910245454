.event-details-info {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex: 1;
    /* padding: 0 20px; */
    /* border-right: 1px solid #5b956d; */
}

.event-details-info > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media only screen and (max-width: 800px) {
    .event-details-info {
        display: flex;
        flex-direction: column;
        gap: 40px;
        flex: 1;
        padding: 20px 0;
        border-right: none;
        border-bottom: 1px solid #5b956d;
    }
}
