.contact-us {
    display: flex;
    justify-content: center;
}

.contact-us-main {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    width: 400px;

    & > button {
        margin-top: 30px;
    }

    & > div > textarea {
        height: 300px;
    }
}

.contact-us-main > .input-container {
    margin: 20px 0;
}

.contact-us-header {
    margin-bottom: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.contact-us-thank-you {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

@media only screen and (max-width: 800px) {
    .contact-us {
        padding: 20px;
    }
}
