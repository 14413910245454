header {
    height: 70px;
    /* color: #fff; */
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 2;
    border-bottom: 1px solid #329f5b;
    background: #fdfeff;
}

.inner-header {
    width: 1024px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.left-header {
    display: flex;
    align-items: center;
    flex: 1;
}

.right-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    flex: 2;
}

.right-header > a {
    color: #000;
}

.login-btn {
    color: #fff;
}

.header-icons > i {
    margin-top: 5px;
}

@media only screen and (max-width: 800px) {
    .inner-header {
        padding: 0 10px;
    }

    .right-header {
        gap: 20px;
    }
}
