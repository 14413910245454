.login-main {
    margin: 10vh 0;
    display: flex;
    flex-direction: column;
    width: 400px;
}

.login-header {
    margin-bottom: 50px;
    text-align: center;
}

.register-link {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.login-wrapper {
    display: flex;
    justify-content: center;
}

.login-main > button,
.login-authenticate-form > button {
    margin-top: 30px;
}

.login-main > .input-container,
.login-authenticate-form > .input-container {
    margin-top: 30px;
}

.login-authenticate-form {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > p {
        text-align: center;
    }
}

.login-forgot-password {
    text-align: right;
    margin-top: 20px;
}

.login-error {
    display: flex;
    align-items: center;
    gap: 10px;
}

.email-confirmed {
    margin: 30px 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid var(--green);
    border-radius: 15px;

    & > div {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}

@media only screen and (max-width: 800px) {
    .login-wrapper {
        padding: 20px;
    }

    .login-main {
        margin: 10vh 0;
        width: 100vw;
    }
}
