@import url('boxicons/css/boxicons.min.css');

:root {
    --green: #329f5b;
    --light-green: #5b956d;
    --red: #eb4034;
    --light-grey: #d8dee2;
    --black: #0d120d;
    --navy-blue: #1f3b55;
    --ice-white: #fdfeff;
    --secondary-text: #65686c;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fdfeff;
    display: flex;
    flex-direction: column;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
}

a {
    text-decoration: none;
    color: #0d120d;
    font-size: 16px;
}

svg {
    height: 25px;
}

.app-container {
    margin: 70px auto;
    padding: 20px;
    max-width: 1024px;
    flex: 1;
    min-height: 100vh;
}

.clickable {
    cursor: pointer;
}

.flex {
    display: flex;
}

.flex.gap {
    gap: 10px;
}

.flex.gap.xl {
    gap: 20px;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.black {
    color: #0d120d;
}

.green {
    color: #329f5b;
}

.red {
    color: #eb4034;
}

.white {
    /* color: var(--ice-white); */
    color: #fff;
}

.light-gray {
    color: var(--light-grey);
}

.secondary-text {
    color: var(--secondary-text);
}

.text-center {
    text-align: center;
}

.disabled {
    filter: opacity(50%);
}

.icon-big {
    height: 50px;
}

.margin-vertical {
    margin: 10px 0;
}

@media only screen and (max-width: 800px) {
    .app-container {
        width: 100vw;
        padding: 0;
    }

    main {
        padding: 30px 10px;
    }
}
