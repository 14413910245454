.closed-event {
    padding: 10px;
}

.closed-event-like {
    display: flex;
    align-items: center;
    gap: 10px;
}

.closed-event-stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 30px;
}

.closed-event-stats.mvp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding-top: 15px;
}

.past-event-team-name {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
}

.past-event-header {
    display: flex;
    gap: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.past-event-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.past-event-actions {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    flex: 1;
}

.past-event-subheader {
    display: flex;
    flex: 1;
}

@media only screen and (max-width: 800px) {
    .past-event {
        padding: 10px;
    }

    .past-event-subheader {
        display: flex;
        flex-direction: column;
    }

    .past-event-actions {
        margin-top: 20px;
        justify-content: flex-start;
    }
}
