.closed-event {
    padding: 10px;
}

.closed-event-like {
    display: flex;
    align-items: center;
    gap: 10px;
}

.closed-event-stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 30px;
}

.closed-event-stats.mvp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    padding-top: 15px;
}

.closed-event-team-name {
    display: flex;
    gap: 25px;
    align-items: center;
    margin-bottom: 30px;
    text-align: center;
    height: 50px;
}
