.event-row {
    /* background: #ffffff; */
    padding: 15px 20px;
    display: flex;
    gap: 30px;
    border: 1px solid #329f5b;
    border-radius: 15px;
    box-shadow: 2px 2px 5px rgb(179 179 179 / 40%);
}

.event-row-record {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.event-row-record-header {
    display: flex;
    justify-content: space-between;
}

.event-row-title {
    display: flex;
    gap: 10px;
}

.event-row-record-details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
}

.event-row-record-details > div {
    display: flex;
    gap: 30px;
}

.event-row-icons {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
}

@media only screen and (max-width: 800px) {
    .event-row-record-details {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .event-row {
        padding: 15px 10px;
        gap: 10px;
    }

    .event-row-record-header {
        flex-direction: column;
        gap: 5px;
    }

    .event-row-icons {
        justify-content: flex-start;
    }
}
