.create-event-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 50%;
}

.create-event-save-btn {
    margin: 40px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .create-event-form {
        display: flex;
        flex-direction: column;
        gap: 25px;
        width: 90%;
    }
}
