.vote-event-user-wrapper {
    display: flex;
    /* width: 120px; */
    gap: 25px;
}

.vote-event-team {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vote-event-like {
    flex: 1;
}

.vote-event-user {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 2;
    width: 100px;
}

.vote-event-user > p {
    margin: 10px 0;
}

.vote-event-stats {
    padding-top: 30px;
    min-width: 60px;
}

.event-details-header > p {
    text-align: center;
}

.vote-event-team > button {
    margin-bottom: 30px;
}

.vote-event-team-name {
    display: flex;
    gap: 25px;
    align-items: center;
    margin-bottom: 30px;
    text-align: center;
}

.vote-event-icons {
    display: flex;
    gap: 10px;
}

@media only screen and (max-width: 800px) {
    .vote-event-user-wrapper {
        display: flex;
        width: 120px;
        gap: 10px;
    }

    .vote-event-team {
        flex: 1;
    }

    .vote-event-teams-wrapper {
        gap: 0px;
    }

    .vote-event-teams-wrapper > button {
        margin: 20px auto;
    }
}
