.email-code {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-code-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
}

.register-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
    width: 400px;
}

.register-form > button {
    margin-top: 30px;
}

.signin-link {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 30px;
}

@media only screen and (max-width: 800px) {
    .register-form {
        width: 100%;
    }

    .register-form > .btn.lg {
        width: 100%;
    }

    .signin-link {
        width: 100%;
    }
}
