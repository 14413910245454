.dashboard {
    display: flex;
    flex-direction: column;
}

.dashboard-section {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
}

.dashboard-header {
    height: 50px;
    display: flex;
    align-items: center;

    width: 100%;
    margin-bottom: 20px;
}

.dashboard-events {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.dashboard-empty-list {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    & > p {
        & > a {
            color: var(--green);
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .dashboard {
        padding: 10px;
    }

    .events-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;
    }

    .dashboard-section {
        margin: 20px 0;
    }

    .dashboard-empty-list {
        justify-content: flex-start;
    }
}
