.btn {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    color: #000;
    border: 1px solid #d8dee2;
    border-radius: 15px;
    text-align: center;
    background-color: #fff;
    outline: none;
    box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.4);
}

.btn.sm {
    width: auto;
    height: 35px;
}

.btn.md {
    width: 300px;
}

.btn.lg {
    width: 400px;
}

.btn.green {
    background-color: var(--green);
    border: none;

    & > h3 {
        color: #ffffff;
    }
}

.btn.red {
    background-color: var(--red);
    border: none;

    & > h3 {
        color: #ffffff;
    }
}

.btn.warning {
    background-color: transparent;
    border: 1px solid var(--red);
}

.btn.success {
    background-color: transparent;
    border: 1px solid var(--green);
}

.btn.transparent {
    background-color: transparent;
    /* border: 1px solid var(--green); */
    border: none;
}

.btn.retro {
    background: #c0c0c0;
    height: 35px;
    border-radius: 0;
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-right: 2px solid #858585;
    border-bottom: 2px solid #858585;
    width: auto;
}

button.btn:disabled {
    filter: opacity(50%);
    cursor: not-allowed;
}
