.user-details {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
}

.user-info {
    display: flex;
    gap: 50px;
}

.user-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 5px;
    justify-content: space-between;
}

.user-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user-name > div {
    margin: 5px 0;
}

.user-stats {
    display: flex;
    gap: 20px;
}

.user-stats > div {
    width: 80px;
}

.user-stats > div > p {
    margin-bottom: 5px;
}

@media only screen and (max-width: 800px) {
    .user-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        margin: 0;
    }

    .user-info {
        gap: 15px;
    }

    .user-main {
        gap: 10px;
    }

    .user-stats {
        flex-wrap: wrap;
        gap: 5px;
        padding-top: 10px;

        & > div {
            width: 70px;
        }
    }
}
