.reset-password-main {
    margin: 20vh 0;
    display: flex;
    flex-direction: column;
    width: 400px;
}

.reset-password-header {
    margin-bottom: 50px;
    text-align: center;
}

.reset-password-wrapper {
    display: flex;
    justify-content: center;
}

.reset-password-main > button {
    margin-top: 50px;
}

.reset-password-main > p {
    text-align: center;
}

.reset-password-main > .input-container {
    margin-top: 30px;
}

@media only screen and (max-width: 800px) {
    .reset-password-wrapper {
        padding: 20px;
    }

    .reset-password-main {
        margin: 10vh 0;
        width: 100vw;
    }
}
