.user-menu {
    display: flex;
    align-items: center;
    position: relative;
}

.user-menu img {
    cursor: pointer;
}

.dropdown-menu {
    margin: 2px 0;
    color: #000;
    overflow: auto;
    z-index: 1;
    width: 200px;
    max-height: 350px;
    right: 0;
    top: 42px;
    background-color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid #dedede;
    border-radius: 5px;
}

.dropdown-menu .menu-element:hover {
    background-color: #2dc58436;
}

.dropdown-menu .menu-element:first-child {
    cursor: default;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    padding: 12px 8px;
}

.dropdown-menu .menu-element {
    cursor: pointer;
    background-color: #fff;
    padding: 8px;
}

.dropdown-menu > .menu-element.disabled {
    cursor: default;
}

.red-counter {
    position: absolute;
    text-align: center;
    font-size: 12px;
    border-radius: 100px;
    background-color: red;
    width: 15px;
    height: 15px;
    color: #eee;
}
