.user-likes {
    display: flex;
    align-items: center;
    gap: 30px;
}

.user-likes * svg {
    margin-left: 10px;
}

.user-history {
    margin: 40px 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.user-history-header {
    height: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.user-history-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.user-history-row {
    background: #f0f0f0;
    padding: 25px 20px;
    margin: 10px 0;
    display: flex;
    gap: 25px;
}

.user-history-record {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
}

.user-history-record-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-history-record-details {
    display: flex;
    gap: 25px;
}

.user-history-trophies {
    display: flex;
    gap: 10px;
}

@media only screen and (max-width: 800px) {
    .user-page {
        padding: 10px;
    }
    .user-history {
        margin: 30px 0;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .user-history-header {
        height: 50px;
        display: flex;
        justify-content: center;
    }
}
