.footer {
    background: var(--green);
    height: 80px;
    /* position: fixed;
    bottom: 0pt;
    left: 0pt; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.inner-footer {
    width: 1024px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    & > div > h3 {
        color: var(--ice-white);
    }
}

.footer-right {
    display: flex;
    gap: 30px;

    & > p {
        color: var(--ice-white);
    }

    & > a > p {
        color: var(--ice-white);
    }
}

@media only screen and (max-width: 800px) {
}
