.select-input-container {
    width: 300px;
}

.select-input-container.full-width {
    width: 100%;
}

.select-input-container.small {
    width: 50%;
}

.select-input {
    font-size: 14px;
    padding: 20px 15px;
    border-radius: 15px;
    width: 100%;
    height: 48px;
    border: 2px solid #dbdde0;
    background: #fff;
    text-align: start;
    position: relative;
    display: flex;
    align-items: center;
    color: var(--black);
}

.select-input:focus {
    border: 2px solid #5b956d;
}

.select-placeholder {
    color: #646464;
}

.select-input > i {
    position: absolute;
    bottom: 11px;
    right: 10px;
    width: 24px;
    height: 24px;
}

.select-menu {
    cursor: pointer;
    border-radius: 15px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    z-index: 1;
    position: absolute;
    overflow: auto;
    width: 100%;
    top: 52px;
    left: 0;
    max-height: 400px;
    padding: 0;
}

.select-option {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select-option.selected {
    background-color: #2dc58436;
}

.select-option:hover {
    background-color: #2dc58436;
}

.select-menu-option > p {
    margin-left: 5px;
}

@media only screen and (max-width: 800px) {
}
