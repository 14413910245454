.credits-page {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.credits-page-icons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > ul {
        padding: 0 20px;
    }

    & > ul > li {
        margin: 20px 0;
    }

    & > ul > li::marker {
        color: var(--green);
    }
}
