.user-settings-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #329f5b;
}

.user-settings-header {
    display: flex;
    gap: 25px;
    align-items: center;
}

.user-settings-header > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.user-settings-content {
    margin-top: 40px;
}

.user-settings-form {
    margin-top: 40px;
}

.user-settings-form > form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.user-settings-form-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user-settings-form > form > button {
    margin-top: 20px;
}

@media only screen and (max-width: 800px) {
    .user-settings {
        padding: 10px;
    }
}
