.event-board {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.event-board-messages {
    min-height: 300px;
    max-height: 700px;
    overflow: auto;
}

.event-board-message-row:first-child {
    padding: 0 0 10px 0;
}

.event-board-message-row {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
}

.event-board-message-row:last-child {
    border-bottom: none;
}

.event-board-message {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.event-board-message > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.event-board-input {
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 3px;
}

.event-board-input > .input-container {
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .event-board-messages {
        height: 500px;
    }
}
