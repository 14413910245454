.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(67, 67, 67, 0.5);
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dialog-container {
    width: 450px;
    box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.4);
    z-index: 10;
    border-radius: 15px;
    background-color: #fff;
}

.dialog-header {
    text-align: center;
    position: relative;
    border-bottom: 1px solid #329f5b;
    padding: 15px;
    font-weight: 600;
    font-size: 16px;

    & > i {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

.dialog-header.red {
    border-radius: 15px 15px 0 0;
    border-bottom: 1px solid #eb4034;
}

.dialog-body {
    text-align: center;
    padding: 15px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dialog-footer {
    padding: 15px;
    display: flex;
    justify-content: center;
    gap: 40px;
}

@media only screen and (max-width: 800px) {
    .dialog-container {
        width: 90vw;
    }
}
