.not-found-wrapper {
    padding: 20px;
    width: 500px;
    height: 200px;
    border: 1px solid var(--red);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 20px auto;

    & > p {
        text-align: center;
    }
}

@media only screen and (max-width: 800px) {
    .not-found-wrapper {
        margin: 100px auto;
        width: 300px;
    }
}
