.section-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fefefe;
    /* border-radius: 25px; */
    gap: 50px;
    box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.4);
}
