.profile-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.profile-img.m {
    width: 40px;
    height: 40px;
}

.profile-img.l {
    width: 70px;
    height: 70px;
}

.profile-img.xl {
    width: 100px;
    height: 100px;
}

.profile-img.xxl {
    width: 250px;
    height: 250px;
}

@media only screen and (max-width: 800px) {
    .profile-img.xxl {
        width: 100px;
        height: 100px;
    }

    .profile-img.xl {
        width: 80px;
        height: 80px;
    }
}
