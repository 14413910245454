.edit-event-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 50%;
}

.edit-event-form-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.edit-event-buttons {
    margin: 40px 0;
    width: 100%;
    display: flex;
    gap: 40px;
}

@media only screen and (max-width: 800px) {
    .edit-event-form {
        width: 100%;
    }

    .edit-event {
        padding: 10px;
    }
}
