.tabs {
    height: 70px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #329f5b;
}

.tab-section {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 20px;
    width: 150px;
}

.tab-dot {
    position: absolute;
    text-align: center;
    font-size: 12px;
    border-radius: 100px;
    background-color: #329f5b;
    width: 10px;
    height: 10px;
    color: #eee;
    top: 15px;
    right: 33px;
}

@media only screen and (max-width: 800px) {
    .tabs {
        justify-content: center;
    }

    .tab-section {
        flex: 1;
        justify-content: center;
    }
}
