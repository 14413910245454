.notifications {
    display: flex;
    align-items: center;
    position: relative;
}

.notifications-title {
    cursor: default;
    border-bottom: 1px solid #eee;
    background-color: #fff;
    padding: 12px 8px;
}

.notifications-dropdown-menu {
    margin: 2px 0;
    color: #000;
    overflow: auto;
    overflow-x: hidden;
    z-index: 1;
    width: 300px;
    max-height: 350px;
    right: 0;
    top: 35px;
    background-color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid #dedede;
    border-radius: 5px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.notifications-menu-element:hover {
    background-color: #2dc58436;
}

.notifications-menu-element {
    cursor: pointer;
    background-color: #fff;
    padding: 10px;
    display: flex;
    gap: 15px;
}

.notifications-menu-element > p {
    word-break: break-word;
}

.new-notifications-dot {
    position: absolute;
    text-align: center;
    font-size: 12px;
    border-radius: 100px;
    background-color: #329f5b;
    width: 10px;
    height: 10px;
    color: #eee;
    top: 15px;
    left: 15px;
}
