.search-bar > i {
    margin-top: 5px;
}

.search-bar-container {
    display: inline-block;
    position: relative;
    width: 300px;
    border: none;
    border-radius: 55px;
    text-align: left;
}

.search-bar-menu {
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;
    position: absolute;
    background: white;
    overflow: auto;
    max-height: 300px;
    width: 100%;
    top: 54px;
}

.search-bar-menu-option {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
}

.search-bar-menu-option:hover {
    background-color: #2dc58436;
}

.search-bar-icon {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #d8dee2;
    height: 34px;
    border-radius: 0 5px 5px 0;
    padding: 9px 10px;
    cursor: pointer;
}

.search-icon {
    height: 18px;
    position: absolute;
    top: 8px;
    left: 10px;
}

.search-bar-container > i {
    position: absolute;
    right: 8px;
    height: 18px;
    top: 4px;
    background-color: #f1f1f1;
    border-radius: 55px;
    color: #3f3f3f;
    cursor: pointer;
}

.search-bar-menu-options-wrapper {
    padding: 10px 0;
    border-bottom: 1px solid #d8dee2;
    display: flex;
    flex-direction: column;
}

.search-bar-menu-options-wrapper > h3 {
    padding: 10px;
}
