.custom-date-picker-wrapper {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.custom-date-picker-wrapper.full-width {
    width: 100%;
}

.custom-date-picker-wrapper.small {
    width: 50%;
}
